import { inject, Injectable, isDevMode, signal, WritableSignal } from '@angular/core';
import {
  deleteField,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
  Unsubscribe,
} from '@angular/fire/firestore';
import { AuthService } from '../../services/auth.service';
import { InviteStatus } from '../../services/invite-listener.service';
import { DialogElement } from './project-dashboard-chatbot/project-dashboard-chatbot.component';
import { EventBus, EventBusEnum } from '../../services/event-bus.service';

export interface ProjectLevelInviteData {
  [email: string]: {
    role: string;
    status: InviteStatus;
  };
}

export interface RolesData {
  [user: string]: {
    role: string;
    email: string;
    memberSince: Timestamp;
  };
}

export interface ProjectData {
  name: string;
  roles: RolesData;
  invites: ProjectLevelInviteData;
  configuration: RagConfiguration;
  asyncUploadEnd: Timestamp;
}

export interface RagConfiguration {
  uploadData: {
    chunkSize: number;
    chunkOverlap: number;
  };
  queryAndPrompt: {
    temperature: number;
    kNearestVectors: number;
    maxTokens: number;
    promptTemplate: string;
    noCommonKnowledgePrompt: boolean;
  };
  embeddingsGenerator: string;
  chatCompletion: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private db: Firestore = inject(Firestore);
  private authService: AuthService;
  public projectData: ProjectData | undefined;
  private _dialogData: DialogElement[] = [];

  private projectDataUnsubscribe: Unsubscribe | undefined;
  private _projectId: string | null | undefined;

  public asyncUploadEnd: WritableSignal<Date | undefined | null> = signal<Date | undefined | null>(null);
  // currently solely used for async uploads. Could probably be extended with not much effort
  public areUploadsRunning = signal(false);

  constructor(
    authService: AuthService,
    private eventBus: EventBus,
  ) {
    this.authService = authService;
  }

  async init(projectId: string | null) {
    isDevMode()
      ? console.log(`${AuthService.performanceInSeconds()}s: initializing project with id ${projectId}`)
      : null;
    this.setAndClearData(projectId);

    await this.fetchProjectDataSync();

    this.eventBus.emitEvent({ type: EventBusEnum.PROJECT_SELECTED });
  }

  private setAndClearData(projectId: string | null) {
    this._projectId = projectId;
    this._dialogData = [];
    this.projectData = undefined;
    this.asyncUploadEnd.set(null);
  }

  get projectId() {
    if (!this._projectId) {
      throw new Error('Projekt-ID nicht verfügbar.');
    }
    return this._projectId;
  }

  async fetchProjectDataSync() {
    if (this.projectDataUnsubscribe) {
      this.projectDataUnsubscribe();
    }

    // necessary so we can assure that project data are instantly available at subpath components
    this.projectData = (await getDoc(this.projectDocRef)).data() as ProjectData;

    this.projectDataUnsubscribe = onSnapshot(this.projectDocRef, (doc) => {
      this.projectData = doc.data() as ProjectData;
      isDevMode() ? console.log(`${AuthService.performanceInSeconds()}s: project data loaded`) : null;
    });
  }

  public get projectDocRef() {
    return doc(this.db, `tenants/${this.authService.tenantId}/projects`, this.projectId);
  }

  deleteProjectInvitationEntry(email: string) {
    const data = {
      invites: {
        [email]: deleteField(),
      },
    };
    setDoc(this.projectDocRef, data, { merge: true });
  }

  async registerInvitationAtProject(newUsersEmail: string, role: string) {
    const data = {
      invites: {
        [newUsersEmail]: {
          role: role,
          inviteDate: serverTimestamp(),
          status: InviteStatus.PENDING,
        },
      },
    };
    await setDoc(this.projectDocRef, data, { merge: true });
  }

  removeMember(email: string) {
    const userId = this.findUserByEmail(email);
    if (userId !== null) {
      this.removeMemberFromProjectDocument(userId);
      this.authService.revokeRole(userId, this.projectId);
    } else {
      console.log('kein Benutzer gefunden.');
    }
  }

  findUserByEmail(email: string): string | null {
    for (const userId in this.projectData?.roles) {
      if (this.projectData?.roles[userId].email === email) {
        return userId;
      }
    }
    return null; // Benutzer nicht gefunden
  }

  private removeMemberFromProjectDocument(userId: string) {
    const data = {
      roles: {
        [userId]: deleteField(),
      },
    };
    setDoc(this.projectDocRef, data, { merge: true });
  }

  get dialogData(): DialogElement[] {
    return this._dialogData;
  }

  resetDialogData() {
    this._dialogData = [];
  }

  addToDialogData(newElement: DialogElement) {
    this._dialogData.push(newElement);
  }

  async persistAsyncUploadEndDate() {
    const data = {
      asyncUploadEnd: Timestamp.fromDate(this.asyncUploadEnd()!),
    };
    await setDoc(this.projectDocRef, data, { merge: true });
  }

  removeAsyncUploadEndDate() {
    const data = {
      asyncUploadEnd: deleteField(),
    };
    setDoc(this.projectDocRef, data, { merge: true });
  }
}
